<template>
  <div>
    <!-- 主体 -->
    <LiefengContent>
      <template v-slot:title>子平台管理</template>
      <!-- 头部搜索层 -->
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Input v-model.trim="searchForm.keyword" placeholder="按子平台名称，编码查询" style="width: 200px;margin-right:10px"></Input>
          <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
          <Button
              icon="ios-add"
              type="primary"
              @click="addForm"
          >新增
          </Button>
        </Form>
      </template>
      <!-- 分页层 -->
      <template v-slot:contentArea>
        <!-- 分页table -->
        <LiefengTable
            :talbeColumns="pageForm.talbeColumns"
            :tableData="pageForm.tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="pageForm.page"
            :total="pageForm.total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageForm.pageSize"
            @hadlePageSize="changeTableSize"
        ></LiefengTable>
        <FormAdd ref="FormAdd" :fullscreen="true" @resetBtn="resetBtn"></FormAdd>
        <FormEdit ref="FormEdit" :fullscreen="true" @resetBtn="resetBtn"></FormEdit>
        <LiefengModal
            title="公众号配置"
            :value="publicStatus"
            @input="changePublic"
            width='600px'
          >
          <template v-slot:contentarea>
            <Form
                :model="publicData"
                :label-colon="true"
                :label-width="120"
            >
              <FormItem>
                <span slot="label" >公众号id:</span>
                <Input style="width:420px" v-model.trim="publicData.a" :maxlength="50" placeholder="请填写公众号id"></Input>
              </FormItem>
              <FormItem>
                <span slot="label" >应用秘钥:</span>
                <Input style="width:420px" v-model.trim="publicData.b" :maxlength="50" placeholder="请填写应用秘钥"></Input>
              </FormItem>
              <FormItem>
                <span slot="label" >服务器地址:</span>
                <Input style="width:420px" v-model.trim="publicData.c" :maxlength="50" placeholder="请填写服务器地址"></Input>
              </FormItem>
              <FormItem>
                <span slot="label" >令牌Token:</span>
                <Input style="width:420px" v-model.trim="publicData.d" :maxlength="50" placeholder="请填写令牌Token"></Input>
              </FormItem>
            </Form>
          </template>
          <template v-slot:toolsbar>
              <Button style="margin-right:10px" type="primary" @click="closePublic">取消</Button>
              <Button type="error" @click="savePublic">保存</Button>
          </template>
        </LiefengModal>
        <LiefengModal
            title="小程序配置"
            :value="appletStatus"
            @input="changeApplet"
            width='600px'
          >
          <template v-slot:contentarea>
            <Form
                :model="appletData"
                :label-colon="true"
                :label-width="120"
            >
              <FormItem>
                <span slot="label" >小程序名称:</span>
                <Input style="width:420px" v-model.trim="appletData.a" :maxlength="50" placeholder="请填写子平台名称"></Input>
              </FormItem>
              <FormItem>
                <span slot="label" class="validate">APPID:</span>
                <Input style="width:420px" v-model.trim="appletData.b" :maxlength="50" placeholder="请填写子平台编码"></Input>
              </FormItem>
            </Form>
          </template>
          <template v-slot:toolsbar>
              <Button style="margin-right:10px" type="primary" @click="closeApplet">取消</Button>
              <Button type="error" @click="saveApplet">保存</Button>
          </template>
        </LiefengModal>
        <!-- 管理员 -->
        <LiefengModal
        :title="titleName"
        :value="adminStatus"
        :fullscreen="true"
        @input="changeAdmin"
    >
      <template v-slot:contentarea>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="changeAdmin(false)">取消</Button>
        <Button type="primary" @click="saveAdmin">确定</Button>
      </template>
      </LiefengModal>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormEdit from './childrens/edit'
import FormAdd from './childrens/add'

export default {
  components: {LiefengContent, LiefengTable, LiefengModal, FormEdit,FormAdd},
  data() {
    return {
      //分页参数
      pageForm: {
        talbeColumns: [
          {
            title: "子平台名称",
            key: "oemName",
            minWidth: 200,
            align: "center"
          },
          {
            title: "子平台编码",
            key: "oemCode",
            minWidth: 100,
            align: "center"
          },
          {
            title: "域名",
            key: "tenantName",
            minWidth: 250,
            align: "center",
            render:(h,params) =>{
              return h('div',{},params.row.extra.domain)
            }
          },
          {
            title: "状态",
            key: "status",
            minWidth: 200,
            align: "center",
             render: (h, params) => {
              return h(
                'i-Switch',
                {
                  props: {
                    size: 'large',
                    value: params.row.status == 1
                  },
                  scopedSlots: {
                    open: () => h('span', '启用'),
                    close: () => h('span', '停用')
                  },
                  on: {
                    'on-change': () => {
                      this.changeStatus(params.row)
                    }
                  }
                }
              )
            }
            // render:(h,params) =>{
            //   return h('div',{},params.row.status == 1 ?'正常':'禁用' )
            // }
          },
          {
            title: "创建时间",
            key: "gmtCreate",
            minWidth: 200,
            align: "center"
          },
          {
            title: "到期时间",
            key: "endTime",
            minWidth: 200,
            align: "center"
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           // this.updateTab(params.row.tenantId)
                                //           this.titleName = `平台管理员 —— ${params.row.oemName}`
                                //           this.adminStatus = true
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "管理员"
                                // ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.goWarrantsystem(params.row.tenantId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    // "子系统管理"
                                    "授权系统"
                                ),
                                //  h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.jumpToSonSystem(params.row.tenantId)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "子系统管理"
                                //     // "授权系统"
                                // ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.changeApplet(true)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "小程序配置"
                                // ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.changePublic(true)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "公众号配置"
                                // ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.updateTab(params.row.tenantId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "修改"
                                ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.updateTab(params.row.tenantId)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "管理员"
                                // ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.updateTab(params.row.tenantId)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "授权组织"
                                // ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.updateTab(params.row.tenantId)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "授权系统"
                                // ),
                                
                                
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                              this.deleteRow(params.row)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0
      },
      loading:false,
      //控制的一些属性
      controlForm:{
        loading:false,
      },
      //查询参数
      searchForm: {
        keyword: ''
      },

      // 公众号配置
      publicStatus:false,
      publicData:{
        a:'',
        b:'',
        b:'',
        d:''
      },

      // 小程序配置
      appletStatus:false,
      appletData:{
        a:'',
        b:'',
        b:'',
        d:''
      },

      // 管理员
      adminStatus:false,
      titleName:'',
    }
  },
  //create函数
  created() {
    this.selectPage()
  },
  methods: {
    /*----- 分页事件部分 -------*/

    //分页事件改变
    changeTableSize(val) {
      this.pageForm.page = val.page
      this.pageForm.pageSize = val.pageSize
      this.selectPage()
    },

    // 授权系统
    goWarrantsystem(tenantId) {
      // this.$router.push('/authCloudSonSystem?tenantId=' + tenantId);
      this.$router.push(`/warrantsystem?tenantId=${tenantId}`);
    },
    //跳转到子系统
    jumpToSonSystem(tenantId) {
      this.$router.push('/authCloudSonSystem?tenantId=' + tenantId);
      // this.$router.push(`/warrantsystem?tenantId=${}`);
    },

    //查询按钮
    searchBtn() {
      this.pageForm.page = 1
      this.selectPage()
    },


    /*----- modal部分 -------*/
    //新增按钮
    addForm() {
      this.$refs.FormAdd.controlForm.formFlag = true;
    },
    updateTab(tenantId){
      this.$refs.FormEdit.getDetail(tenantId)
      this.$refs.FormEdit.controlForm.formFlag = true
    },
    // 方法部分

    /*----- 接口部分 -------*/

    selectPage() {
      this.loading = true
      this.$get('/auth/api/auth/pc/TenantApi/selectByPage', {
        page: this.pageForm.page,
        pageSize: this.pageForm.pageSize,
        ...this.searchForm,
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.pageForm.tableData = res.dataList
          this.pageForm.total = res.maxCount
          this.pageForm.currentPage = res.currentPage
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    async deleteRow(data) {
      this.$Modal.confirm({
        title: "删除确定",
        content: `您正在对 <span style='color:red'>${data.oemName}</span> 进行删除操作、请确保子平台下无数据、并且当前操作不可逆转，是否继续？`,
        onOk: () => {
          this.$post('/auth/api/auth/pc/TenantApi/deleteById',{
                tenantId:data.tenantId
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.resetBtn();
                }else{
                    this.$Message.error({
                        content:'删除失败',
                        background:true
                    })
                    return
                }
            })
        },
      });
    },

    // 更改状态
    changeStatus(data){
      let status = ''
      if(data.status == 1){
        status = 2
      }else {
        status = 1
      }
      this.$post('/auth/api/auth/pc/TenantApi/changeStatus',{
        status:status,
        tenantId:data.tenantId
      }).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content:'操作成功',
            background:true
          })
          this.selectPage()
        }else{
          this.$Message.error({
            content:'操作失败',
            background:true
          })
          this.selectPage()
        }
      } )
    },

    // 公众号配置
    changePublic(val){
      this.publicStatus = val
    },
    closePublic(){
      this.publicStatus = false
    },
    savePublic(){
      this.publicStatus = false
    },


    // 小程序配置
    changeApplet(val){
      this.appletStatus = val
    },
    closeApplet(){
      this.appletStatus = false
    },
    saveApplet(){
      this.appletStatus = false
    },

    // 管理员设置
    changeAdmin(val){
      this.adminStatus = val
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>